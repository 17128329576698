<template>
  <div class="group-monitor">
    <div
      class="container"
      :style="`grid-template-columns: repeat(auto-fill, ${100/this.rowValue}%)`"
    >
      <!-- 窗口 -->
      <MapItem class="item" v-for="i in items" :key="i"></MapItem>
    </div>
    <div class="footer" @mouseover="onMouseover()">
      <div class="footer-menu" id="menu" @mouseout="onMouseouet()">
        <el-button @click="change">{{this.rowValue}} 行 X {{this.colValue}} 列</el-button>
      </div>
      <div class="btn-row" v-if="daskRow">
        <div class="group-btn">
          <span v-for="i in 10" :key="i" @click="rowClick(i)">{{i}}</span>
        </div>
        <div class="group-btn">
          <span v-for="i in 10" :key="i" @click="colClick(i)">{{i}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MapItem from './MapItem.vue'
export default {
  // `name`与路由保持一致
  name: 'GroupMonitoring',
  components: {
    MapItem
  },
  data() {
    return {
      menuId: null,
      time: null,
      rowValue: 2,
      colValue: 2,
      items: 4,
      daskRow: false,
    };
  },
  watch: {

  },
  created() {
  },
  mounted() {
    this.menuId = document.getElementById("menu");
    clearTimeout(this.time);
  },
  methods: {
    //选择行
    rowClick(val) {
      this.rowValue = val;
      this.colValue = val;
      this.items = val * this.colValue;
    },
    //选择列
    colClick(val) {
      this.colValue = val;
      this.items = val * this.rowValue;
    },
    // 行列选择显隐
    change() {
      this.daskRow = !this.daskRow;
    },
    // 鼠标悬停行列显隐
    onMouseover() {
      this.menuId.style.display = "block";
    },
    // 鼠标悬停行列显隐
    onMouseouet() {
      this.time = setTimeout(() => {
        this.menuId.style.display = "none";
        this.daskRow = false;
        clearTimeout(this.time);
      }, 1000 * 20);
    },
  }
}
</script>

<style lang="scss" scoped>
.group-monitor {
  position: relative;
  height: calc(100vh - 63px);
  width: 100%;
}

.container {
  display: grid;
  height: 98%;
}

.footer {
  width: 100%;
  height: 2%;
  background: #fff;
}
.footer-menu {
  z-index: 10001;
  display: none;
  width: 100%;
  height: 45px;
  background: #e9e9e9;
  position: absolute;
  bottom: 0;
  .el-button {
    margin: 7px;
    border-radius: 0;
  }
}
.btn-row {
  width: 69px;
  height: auto;
  position: absolute;
  bottom: 37px;
  z-index: 10001;
  background: #ffffff;
  padding: 4px;
  box-sizing: border-box;
  left: 8px;
  border-bottom: 1px #ccc solid;
  display: flex;
  .group-btn {
    cursor: pointer;
    width: 40%;
    margin: 0 2px;
    & > span {
      width: 100%;
      padding: 5px 0;
      text-align: center;
      border: 1px #ccc solid;
      display: inline-block;
      font-size: 12px;
    }
  }
}
</style>
