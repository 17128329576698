<template>
  <div v-if="modal">
    <el-dialog
      width="415px"
      :visible="modal"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <!-- header -->
      <div slot="title" class="header-title">
        <span>选择车辆进行分组监控</span>
        <span class="exit-icon" title="关闭" @click="onModalChange(false)">
          <i size="32" class="el-icon-close"></i>
        </span>
      </div>
      <!-- body -->
      <div class="modal-main">
        <GroupVehicleTree
          class="TreeOuter"
          :defaultExpandAll="false"
          :showSearch="true"
          showVehicle
          :showCheckbox="true"
          :updateVehicleTime="15"
          :visibleFilter="onTreeFilter"
          @node-check="onNodeClick"
        >
          <template v-slot="{data}">
            <div
              class="NodeLabel"
              :title="getNodeLabel(data)"
            >{{getNodeLabel(data)}}</div>
          </template>
        </GroupVehicleTree>
      </div>
      <div class="title-wrap">
        <label for="itemTitleInput">标题：</label>
        <el-input v-model="itemTitle" placeholder="请输入标题" id="itemTitleInput"></el-input>
      </div>
      <!-- footer -->
      <span slot="footer" class="bottom">
        <el-button size="mini" @click="onModalChange(false)">取 消</el-button>
        <el-button size="mini" type="primary" @click="onSaveData(false)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import SelectGroupTree from '@/components/GroupTree/SelectGroupTree';
import GroupTree from '@/components/GroupTree';
import GroupVehicleTree from '@/components/VehicleGroupTree';
export default {
  name: 'ModalChoose',
  components: {
    SelectGroupTree,
    GroupTree,
    GroupVehicleTree
  },
  model: {
    prop: 'modal',
    event: 'modal-change'
  },
  props: {
    modal: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      //选中的区域list
      selectNode: [],
      //该区域的标题名称
      itemTitle: '',
      titles: []
    };
  },

  watch: {
    modal: {
      // immediate: true,
      async handler(val) {
        if (val) {
          this.selectNode = [];
          this.itemTitle = '';
          this.titles = [];
        }
      }
    },
  },

  methods: {
    onTreeFilter(data) {
      if (data.nodeType === 2) {
        return [0, 3, 2].includes(data.Z);
      }
      return true;
    },
    getNodeLabel(data) {
      // 车组
      if (data.nodeType === 1) return data.groupName;
      // 车辆
      if (data.nodeType === 2) return data.P;
      // 通道号
      if (data.nodeType === 2) return data.label;
    },

    onNodeClick(data, checked, node, allCheckeds) {
      this.selectNode = allCheckeds;

      const title = data.nodeType === 1 ? data.groupName : data.P;

      // 判断勾选状态
      if (checked) this.titles.push(title);

      if (!checked) this.titles = this.titles.filter(item => item !== title);

      this.itemTitle = this.titles.join(',');
    },

    onModalChange(modal, eventName = 'on-cancel', ...options) {
      this.$emit('modal-change', !!modal);
      this.$emit(eventName, ...options);
    },

    //校验区域和标题，并传给父组件
    onSaveData(modal) {
      const vehicles = this.selectNode.filter(item => item.nodeType === 2);
      const groups = this.selectNode.filter(item => item.nodeType !== 2);

      if (this.selectNode) {
        this.$emit('modal-change', !!modal);
        this.$emit('on-confirm', { node: { vehicles, groups }, title: this.itemTitle });
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.TreeOuter {
  text-align: start;
}
.el-dialog {
  padding: 0;
}
.modal-main {
  height: 300px;
  padding: 10px 10px 0 10px;
  overflow: auto;
}

.header {
  position: relative;
}
.exit-icon {
  // position: absolute;
  // right: 0px;
  // top: 0px;
  cursor: pointer;
  width: 20px;
  // text-align: center;
}
::v-deep .el-dialog__body {
  padding: 0;
}
::v-deep .el-tabs--border-card {
  border: 0;
  box-shadow: none;
}

::v-deep .el-tabs__content {
  padding: 0;
}
.header-title {
  background: #212d3e;
  color: #fff;
  padding: 15px 20px;
  line-height: normal;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
::v-deep .el-dialog__header {
  padding: 0;
}
.title-wrap {
  display: flex;
  padding: 15px;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  label {
    width: 60px;
  }
}
</style>

