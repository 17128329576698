
<template>
  <div>
    <div v-if="flag" class="map-wrap">
      <div class="mapTitle">
        <span>{{mapTitle}} 车辆({{extend.vehicleCount}}) 定位({{extend.posCount}})</span>
        <img :src="closeIcon" @click.stop="closeMap" style="width:30px" />
      </div>
      <BSJMap ref="mapRef" v-if="flag" :zoom="15" :center="getCenter()">
        <!-- 展示地图信息窗体 -->
        <InfoWindow
          v-model="showInfo"
          :showClose="true"
          :offset="[15, -15]"
          :lngLat="getInfoLngLat()"
          :width="332"
          :height="250"
          :color="'#fff'"
        >
          <div class="window-title">{{selectObj.plate}}</div>
          <div class="window-info">
            <div class="info-wrap">
              <div class="info-item">
                <label class="RowTitle">车辆号码</label>
                <strong>{{selectObj.plate}}</strong>
              </div>
              <div class="info-item">
                <label class="RowTitle">车辆颜色</label>
                <strong>{{selectObj.color}}</strong>
              </div>
              <div class="info-item">
                <label class="RowTitle">当前报警</label>
                <div class="RowContent" :title="selectObj.alarm">
                  <TextScroll :text="selectObj.alarm"></TextScroll>
                </div>
              </div>
              <div class="info-item">
                <label class="RowTitle">车辆状态</label>
                <strong>{{selectObj.online}}</strong>
              </div>
              <div class="info-item">
                <label class="RowTitle">车辆位置</label>
                <div class="RowContent" :title="selectObj.address">
                  <TextScroll :text="selectObj.address"></TextScroll>
                </div>
              </div>
            </div>
            <div class="info-img">
              <img markerimg :src="defaultIcon" />
            </div>
          </div>
        </InfoWindow>

        <!-- 聚合点标记 -->
        <MapClusterer
          v-model="flag"
          :points="dataList"
          pointKey="id"
          :markerOffset="[15, -10]"
          @markerClick="markerClick($event)"
        >
          <template #marker>
            <Iconfont name="icon-a-zu517" :size="32"></Iconfont>

            <!-- <img markerimg :src="defaultIcon" /> -->
          </template>
        </MapClusterer>

        <!--搜索的点-->
        <MapMarker
          v-if="markerLngLat.length"
          :lngLat="markerLngLat"
          :offset="[15, -10]"
          :rotate="0"
          autoFitView
          autoPanTo
        >
          <Iconfont name="icon-shishidingwei" :size="32"></Iconfont>
        </MapMarker>
      </BSJMap>

      <el-autocomplete
        class="placeSearch"
        prefix-icon="el-icon-search"
        v-model="searchPlace"
        :fetch-suggestions="querySearch"
        placeholder="请输入内容"
        :trigger-on-focus="false"
        @select="handleSelect"
      >
        <template slot-scope="{ item }">
          <div class="name" :title="item.name">{{ item.name }}</div>
        </template>
      </el-autocomplete>
    </div>
    <img src="../../assets/images/分组监控添加.png" @click="chooseGroup" v-else />
    <ChooseGroup v-model="dialogVisible" @on-confirm="selectNode" />
  </div>
</template>

<script>
import ChooseGroup from './ModalChoose';
import { getVehicleLastPosInfo } from '@/api/getRule.js';
import GPS from "@/utils/address";
import { getAddress, getAddresses, placeSearch } from '@/components/AMap/utils';
import TextScroll from '@/components/TextScroll';

import BSJMap, {
  InfoWindow,
  MapMarker,
  MapPolyline,
  MapPolygon,
  MapRectangle,
  MapCircle,
  MapTrack,
  MapClusterer,
} from '@/components/AMap';
export default {
  // `name`与路由保持一致
  name: 'MapItem',

  components: {
    ChooseGroup,
    BSJMap,
    InfoWindow,
    MapMarker,
    MapPolyline,
    MapPolygon,
    MapRectangle,
    MapCircle,
    MapTrack,
    MapClusterer,
    TextScroll
  },
  computed: {
    closeIcon() {
      return require('@/assets/images/header/关闭.png');
    }
  },
  data() {
    return {
      showInfo: false,
      selectObj: {},
      defaultIcon: require('@/assets/images/默认点标记.png'),
      //监控弹框
      dialogVisible: false,
      flag: false,
      dataList: [],
      locationList: [],

      searchPlace: '',// 输入的地点
      markerLngLat: [], // 搜索的经纬度
    };
  },
  watch: {
  },
  created() {
  },
  mounted() {

  },
  methods: {
    // 输入建议
    async querySearch(queryString, cb) {
      const result = await placeSearch(this.searchPlace);

      cb(result);
    },
    handleSelect(item) {
      const { location } = item;

      this.searchPlace = item.name;
      this.markerLngLat = [location.lng, location.lat];
    },


    // 获取marker的坐标
    getInfoLngLat() {
      let arr = Object.keys(this.selectObj);
      if (arr.length !== 0) {
        return [this.selectObj.lng, this.selectObj.lat];
      } else {
        return [this.dataList[0].lon, this.dataList[0].lat];
      }
    },
    //获取地图中心点地址
    getCenter() {
      let arr = Object.keys(this.selectObj);
      if (arr.length !== 0) {
        return [this.selectObj.lng, this.selectObj.lat];
      } else {
        return [this.dataList[0].lon, this.dataList[0].lat];
      }
    },
    // 车辆图标点击
    markerClick(val) {
      this.selectObj = val;
      this.showInfo = true;
      console.log(this.selectObj);
    },
    // 获取车辆位置数据
    getLocation(locationList) {
      getAddresses(locationList).then(res => {
        this.dataList = this.dataList.map(item => {
          let matchAddress = res.find(subItem => item.vehicleId === subItem.tag);
          if (matchAddress) return { ...item, address: matchAddress.address };
          else return { ...item };
        });
      }).catch(err => console.log(err));
    },
    // 选择要查询的节点数据
    async selectNode({ node, title }) {
      const { vehicles, groups } = node;
      // 查询车辆信息
      const result = await getVehicleLastPosInfo({
        vehicleId: vehicles.map(p => p.V).join(','),
        groupId: groups.map(p => p.groupId).join(','),
      });

      if (!result.flag) {
        this.closeMap();
        return this.$message.warning(result.msg);
      }
      if (result.extend) {
        this.extend = result.extend;
      }
      // 有数据展示地图
      if (result.obj) {
        this.locationList = [];
        //先纠偏，后根据经纬度获取车辆位置信息
        this.dataList = result.obj.map((item, i) => {
          let point = GPS.delta(item.lat, item.lon);
          this.locationList.push({ lon: point.lon, lat: point.lat, tag: item.vehicleId });
          return { ...item, id: i, lng: point.lon, lon: point.lon, lat: point.lat, address: '' };
        });
        // 根据车辆ID匹配address对应项
        this.getLocation(this.locationList);
        //位置信息获取出错时

        this.mapTitle = title;
        this.flag = true;
      }
      // 无数据关闭窗口
      else {
        this.closeMap();
        return this.$message.warning(result.msg);
      }

      // this.showInfo = true;
    },
    // 关闭窗口
    closeMap(index) {
      this.flag = false;
      this.showInfo = false;
      this.dataList = [];
    },
    chooseGroup(index) {
      this.selectIndex = index;
      this.dialogVisible = true;
    },
  }
}
</script>

<style lang="scss" scoped>
.placeSearch {
  width: 140px;
  position: absolute;
  top: 45px;
  right: 80px;
}
.item {
  text-align: center;
  border: 2px solid #e9e9e9;
  background: #6b7c9b;
  position: relative;
  & > img {
    width: 60px;
    height: 60px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -30px;
    margin-top: -30px;
    cursor: pointer;
  }
}
.map-wrap {
  display: flex;
  height: 100%;
  flex-direction: column;
}
.mapTitle {
  color: #fff;
  background: #212d3e;
  padding: 4px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.window-title {
  width: 100%;
  background: #212d3e;
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 15px;
  padding: 6px 12px;
}
.RowContent {
  color: #000000;
  flex: 1;
  overflow: hidden;
  span {
    margin: 0 5px 0 0;
  }
}
.RowTitle {
  color: #303133;
  margin-right: 20px;
  white-space: nowrap;
}
.window-info {
  display: flex;
  .info-wrap {
    flex: 1;
    .info-item {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      margin: 12px 0 12px 6px;
      font-size: 14px;
      & > strong {
        text-align: start;
        color: #303133;
      }
      // align-items: flex-start;
    }
  }
  .info-img {
    width: 100px;
    align-self: center;
  }
}
</style>

